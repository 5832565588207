import * as React from "react";

export const InfoIcon: React.FunctionComponent<{}> = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16">
            <g fill="none" fillRule="evenodd" transform="translate(.5 .5)">
                <circle cx="7.5" cy="4" r="1" fill="#CCC" />
                <circle
                    cx="7.5"
                    cy="7.5"
                    r="7"
                    stroke="#CCC"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path stroke="#CCC" strokeLinecap="round" strokeLinejoin="round" d="M7.5 11.5v-4" />
            </g>
        </svg>
    );
};
