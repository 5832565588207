import * as React from "react";
import styled from "@emotion/styled";

import { Text } from "./elements";

interface Props {
    text: string;
    disabled?: boolean;
}

export const Separator: React.FunctionComponent<Props> = ({text, disabled, ...props}) => {
    return (
        <Container>
            <Line area="left" disabled={disabled} />
            <Center disabled={disabled}>{text}</Center>
            <Line area="right" disabled={disabled} />
        </Container>
    )
};

const Container = styled.div`
    display: grid;
    grid-template-areas: "left text right";
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
`;

const Line = styled.div<{area: string; disabled?: boolean;}>`
    grid-area: ${(props) => props.area};
    height: 1px;
    background: #757575;
`;

const Center = styled(Text)`
    color: ${props => props.disabled ? "#757575" : "#111213"};
    grid-area: text;
    margin: 0 8px;
`;
