import * as React from "react";

export const CVVinfoIcon: React.FunctionComponent<{}> = () => {
    return (
        <svg
            width="234px"
            height="123px"
            viewBox="0 0 234 123"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Group 6</title>e
            <desc>Created with Sketch.</desc>
            <defs>
                <rect id="path-1" x="0" y="0" width="234" height="122" rx="4" />
                <pattern
                    id="pattern-3"
                    width="4.8"
                    height="4.8"
                    x="1.2"
                    y="24.2"
                    patternUnits="userSpaceOnUse"
                >
                    <use xlinkHref="#image-4" transform="scale(0.5,0.5)" />
                </pattern>

                <image
                    id="image-4"
                    width="9.6"
                    height="9.6"
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAEGWlDQ1BrQ0dDb2xvclNwYWNlR2VuZXJpY1JHQgAAOI2NVV1oHFUUPrtzZyMkzlNsNIV0qD8NJQ2TVjShtLp/3d02bpZJNtoi6GT27s6Yyc44M7v9oU9FUHwx6psUxL+3gCAo9Q/bPrQvlQol2tQgKD60+INQ6Ium65k7M5lpurHeZe58853vnnvuuWfvBei5qliWkRQBFpquLRcy4nOHj4g9K5CEh6AXBqFXUR0rXalMAjZPC3e1W99Dwntf2dXd/p+tt0YdFSBxH2Kz5qgLiI8B8KdVy3YBevqRHz/qWh72Yui3MUDEL3q44WPXw3M+fo1pZuQs4tOIBVVTaoiXEI/MxfhGDPsxsNZfoE1q66ro5aJim3XdoLFw72H+n23BaIXzbcOnz5mfPoTvYVz7KzUl5+FRxEuqkp9G/Ajia219thzg25abkRE/BpDc3pqvphHvRFys2weqvp+krbWKIX7nhDbzLOItiM8358pTwdirqpPFnMF2xLc1WvLyOwTAibpbmvHHcvttU57y5+XqNZrLe3lE/Pq8eUj2fXKfOe3pfOjzhJYtB/yll5SDFcSDiH+hRkH25+L+sdxKEAMZahrlSX8ukqMOWy/jXW2m6M9LDBc31B9LFuv6gVKg/0Szi3KAr1kGq1GMjU/aLbnq6/lRxc4XfJ98hTargX++DbMJBSiYMIe9Ck1YAxFkKEAG3xbYaKmDDgYyFK0UGYpfoWYXG+fAPPI6tJnNwb7ClP7IyF+D+bjOtCpkhz6CFrIa/I6sFtNl8auFXGMTP34sNwI/JhkgEtmDz14ySfaRcTIBInmKPE32kxyyE2Tv+thKbEVePDfW/byMM1Kmm0XdObS7oGD/MypMXFPXrCwOtoYjyyn7BV29/MZfsVzpLDdRtuIZnbpXzvlf+ev8MvYr/Gqk4H/kV/G3csdazLuyTMPsbFhzd1UabQbjFvDRmcWJxR3zcfHkVw9GfpbJmeev9F08WW8uDkaslwX6avlWGU6NRKz0g/SHtCy9J30o/ca9zX3Kfc19zn3BXQKRO8ud477hLnAfc1/G9mrzGlrfexZ5GLdn6ZZrrEohI2wVHhZywjbhUWEy8icMCGNCUdiBlq3r+xafL549HQ5jH+an+1y+LlYBifuxAvRN/lVVVOlwlCkdVm9NOL5BE4wkQ2SMlDZU97hX86EilU/lUmkQUztTE6mx1EEPh7OmdqBtAvv8HdWpbrJS6tJj3n0CWdM6busNzRV3S9KTYhqvNiqWmuroiKgYhshMjmhTh9ptWhsF7970j/SbMrsPE1suR5z7DMC+P/Hs+y7ijrQAlhyAgccjbhjPygfeBTjzhNqy28EdkUh8C+DU9+z2v/oyeH791OncxHOs5y2AtTc7nb/f73TWPkD/qwBnjX8BoJ98VQNcC+8AAAAJcEhZcwAAC4kAAAuJATfJy60AAAEtSURBVBgZPY9BS4RQFIVH37hUHB2xoMCFoYNIlMsWzT5m6G9EFK1a9kf6OVERJcGMCa1aNCmlI2FSaKCdK2MPrs9773kf5/Rs294fj8f93uoYhrE2Go22uh43T8U3TbMdRdG553kCLTmOa+q6PrYsa496nIY+bLlc3mmatllV1RS02zAMc1VVH/DgCPM8TdNXEjNSo5ljuNGJgyAoSIzVv5g5jqMkSfJD4uFwSOSJ67o3vu8Xg8HAJzLmL0xRlEtd158hTmBjDtJ6URSH+L/OsqwlC4Lwy6B+hPkT3G9YvqOeiIyadmQwCgrzCcqM5/kz0DtxawPkCQWM47huw0D8JcvyDH5OEWoBvx+YtQHLsjwQRfGemaa5Cy8xKpckKWSMXSDEFfrvVcAdeOz/ASTarhMHqp0NAAAAAElFTkSuQmCC"
                />

                <circle id="path-5" cx="59" cy="33.0572656" r="22" />
                <filter
                    x="-30.7%"
                    y="-26.1%"
                    width="161.4%"
                    height="161.4%"
                    filterUnits="objectBoundingBox"
                    id="filter-7"
                >
                    <feMorphology
                        radius="0.5"
                        operator="dilate"
                        in="SourceAlpha"
                        result="shadowSpreadOuter1"
                    />
                    <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                    <feGaussianBlur
                        stdDeviation="4"
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    />
                    <feComposite
                        in="shadowBlurOuter1"
                        in2="SourceAlpha"
                        operator="out"
                        result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
                        type="matrix"
                        in="shadowBlurOuter1"
                    />
                </filter>
                <pattern
                    id="pattern-8"
                    width="4.8"
                    height="4.8"
                    x="8.08270365"
                    y="38.1330677"
                    patternUnits="userSpaceOnUse"
                >
                    <use xlinkHref="#image-9" transform="scale(0.5,0.5)" />
                </pattern>
                <image
                    id="image-9"
                    width="9.6"
                    height="9.6"
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAEGWlDQ1BrQ0dDb2xvclNwYWNlR2VuZXJpY1JHQgAAOI2NVV1oHFUUPrtzZyMkzlNsNIV0qD8NJQ2TVjShtLp/3d02bpZJNtoi6GT27s6Yyc44M7v9oU9FUHwx6psUxL+3gCAo9Q/bPrQvlQol2tQgKD60+INQ6Ium65k7M5lpurHeZe58853vnnvuuWfvBei5qliWkRQBFpquLRcy4nOHj4g9K5CEh6AXBqFXUR0rXalMAjZPC3e1W99Dwntf2dXd/p+tt0YdFSBxH2Kz5qgLiI8B8KdVy3YBevqRHz/qWh72Yui3MUDEL3q44WPXw3M+fo1pZuQs4tOIBVVTaoiXEI/MxfhGDPsxsNZfoE1q66ro5aJim3XdoLFw72H+n23BaIXzbcOnz5mfPoTvYVz7KzUl5+FRxEuqkp9G/Ajia219thzg25abkRE/BpDc3pqvphHvRFys2weqvp+krbWKIX7nhDbzLOItiM8358pTwdirqpPFnMF2xLc1WvLyOwTAibpbmvHHcvttU57y5+XqNZrLe3lE/Pq8eUj2fXKfOe3pfOjzhJYtB/yll5SDFcSDiH+hRkH25+L+sdxKEAMZahrlSX8ukqMOWy/jXW2m6M9LDBc31B9LFuv6gVKg/0Szi3KAr1kGq1GMjU/aLbnq6/lRxc4XfJ98hTargX++DbMJBSiYMIe9Ck1YAxFkKEAG3xbYaKmDDgYyFK0UGYpfoWYXG+fAPPI6tJnNwb7ClP7IyF+D+bjOtCpkhz6CFrIa/I6sFtNl8auFXGMTP34sNwI/JhkgEtmDz14ySfaRcTIBInmKPE32kxyyE2Tv+thKbEVePDfW/byMM1Kmm0XdObS7oGD/MypMXFPXrCwOtoYjyyn7BV29/MZfsVzpLDdRtuIZnbpXzvlf+ev8MvYr/Gqk4H/kV/G3csdazLuyTMPsbFhzd1UabQbjFvDRmcWJxR3zcfHkVw9GfpbJmeev9F08WW8uDkaslwX6avlWGU6NRKz0g/SHtCy9J30o/ca9zX3Kfc19zn3BXQKRO8ud477hLnAfc1/G9mrzGlrfexZ5GLdn6ZZrrEohI2wVHhZywjbhUWEy8icMCGNCUdiBlq3r+xafL549HQ5jH+an+1y+LlYBifuxAvRN/lVVVOlwlCkdVm9NOL5BE4wkQ2SMlDZU97hX86EilU/lUmkQUztTE6mx1EEPh7OmdqBtAvv8HdWpbrJS6tJj3n0CWdM6busNzRV3S9KTYhqvNiqWmuroiKgYhshMjmhTh9ptWhsF7970j/SbMrsPE1suR5z7DMC+P/Hs+y7ijrQAlhyAgccjbhjPygfeBTjzhNqy28EdkUh8C+DU9+z2v/oyeH791OncxHOs5y2AtTc7nb/f73TWPkD/qwBnjX8BoJ98VQNcC+8AAAAJcEhZcwAAC4kAAAuJATfJy60AAAEtSURBVBgZPY9BS4RQFIVH37hUHB2xoMCFoYNIlMsWzT5m6G9EFK1a9kf6OVERJcGMCa1aNCmlI2FSaKCdK2MPrs9773kf5/Rs294fj8f93uoYhrE2Go22uh43T8U3TbMdRdG553kCLTmOa+q6PrYsa496nIY+bLlc3mmatllV1RS02zAMc1VVH/DgCPM8TdNXEjNSo5ljuNGJgyAoSIzVv5g5jqMkSfJD4uFwSOSJ67o3vu8Xg8HAJzLmL0xRlEtd158hTmBjDtJ6URSH+L/OsqwlC4Lwy6B+hPkT3G9YvqOeiIyadmQwCgrzCcqM5/kz0DtxawPkCQWM47huw0D8JcvyDH5OEWoBvx+YtQHLsjwQRfGemaa5Cy8xKpckKWSMXSDEFfrvVcAdeOz/ASTarhMHqp0NAAAAAElFTkSuQmCC"
                />
                <circle id="path-10" cx="59" cy="22.0572656" r="22" />
                <filter
                    x="-30.7%"
                    y="-26.1%"
                    width="161.4%"
                    height="161.4%"
                    filterUnits="objectBoundingBox"
                    id="filter-12"
                >
                    <feMorphology
                        radius="0.5"
                        operator="dilate"
                        in="SourceAlpha"
                        result="shadowSpreadOuter1"
                    />
                    <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                    <feGaussianBlur
                        stdDeviation="4"
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    />
                    <feComposite
                        in="shadowBlurOuter1"
                        in2="SourceAlpha"
                        operator="out"
                        result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
                        type="matrix"
                        in="shadowBlurOuter1"
                    />
                </filter>
                <pattern
                    id="pattern-13"
                    width="0"
                    height="0"
                    x="12.8827037"
                    y="42.9330677"
                    patternUnits="userSpaceOnUse"
                >
                    <use xlinkHref="#image-14" transform="scale(0.1,0.1)" />
                </pattern>
                <image id="image-14" width="0" height="0" />
            </defs>
            <g id="Subscribe-Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="login-subscribe3-350px-copy" transform="translate(-76.000000, -434.000000)">
                    <g id="Group-6" transform="translate(76.000000, 434.000000)">
                        <g id="Group-4">
                            <g id="Dropdown/List/bg" transform="translate(0.040341, 0.500000)">
                                <mask id="mask-2" fill="white">
                                    <use xlinkHref="#path-1" />
                                </mask>
                                <rect
                                    stroke="#D7D8DA"
                                    strokeWidth="1"
                                    x="0.5"
                                    y="0.5"
                                    width="233"
                                    height="121"
                                    rx="4"
                                />
                            </g>
                            <g id="visa" transform="translate(16.040341, 16.500000)">
                                <rect
                                    id="Rectangle"
                                    fill="#1A1876"
                                    x="0"
                                    y="0"
                                    width="27.0588235"
                                    height="20"
                                    rx="3"
                                />
                                <polygon
                                    id="Path"
                                    fill="#FFFFFF"
                                    points="10.7282353 12.8235294 12.2629412 12.8235294 13.2235294 7.19058824 11.6882353 7.19058824"
                                />
                                <path
                                    d="M16.3194118,9.45235294 C15.7829412,9.19176471 15.4541176,9.01764706 15.4576471,8.75411765 C15.4576471,8.51882353 15.7358824,8.26941176 16.34,8.26941176 C16.7851341,8.25827266 17.2264024,8.35451637 17.6264706,8.55 L17.8347059,7.32941176 C17.3934014,7.17021941 16.9273612,7.09055442 16.4582353,7.09411765 C14.9411765,7.09411765 13.87,7.85882353 13.8635294,8.95294118 C13.8552941,9.76235294 14.6282353,10.2141176 15.2088235,10.4823529 C15.8064706,10.7582353 16.0076471,10.9341176 16.0047059,11.1805882 C16.0005882,11.5582353 15.5276471,11.7311765 15.0858824,11.7311765 C14.514384,11.7445217 13.9489219,11.6119125 13.4429412,11.3458824 L13.2276471,12.6064706 C13.7749234,12.8087192 14.3542058,12.910547 14.9376471,12.9070588 C16.5517647,12.9070588 17.5994118,12.1517647 17.6111765,10.9829412 C17.6141176,10.3417647 17.2052941,9.85411765 16.3194118,9.45235294 Z"
                                    id="Path"
                                    fill="#FFFFFF"
                                />
                                <path
                                    d="M21.7688235,7.19588235 L20.5823529,7.19588235 C20.23957,7.15198739 19.9099474,7.34344343 19.7782353,7.66294118 L17.4982353,12.8235294 L19.1105882,12.8235294 C19.1105882,12.8235294 19.3741176,12.1294118 19.4335294,11.9770588 L21.4,11.9770588 C21.4458824,12.1747059 21.5870588,12.8211765 21.5870588,12.8211765 L23.0117647,12.8211765 L21.7688235,7.19588235 Z M19.8758824,10.8270588 C19.9394118,10.6641176 20.5658824,9.05529412 20.6911765,8.71470588 C20.8982353,9.68529412 20.6964706,8.74352941 21.15,10.8270588 L19.8758824,10.8270588 Z"
                                    id="Shape"
                                    fill="#FFFFFF"
                                    fillRule="nonzero"
                                />
                                <path
                                    d="M9.44058824,7.19470588 L7.93705882,11.0358824 L7.77705882,10.2552941 L7.23882353,7.66705882 C7.15771085,7.35702419 6.86066587,7.15406018 6.54235294,7.19117647 L4.06647059,7.19117647 L4.04705882,7.30882353 C4.6099957,7.44164406 5.15250746,7.64958712 5.66,7.92705882 L7.02470588,12.8194118 L8.64941176,12.8194118 L11.0664706,7.19705882 L9.44058824,7.19470588 Z"
                                    id="Path"
                                    fill="#FFFFFF"
                                />
                            </g>
                            <g id="mastercard" transform="translate(51.099165, 16.500000)">
                                <rect
                                    id="Rectangle"
                                    fill="#243747"
                                    x="0"
                                    y="0"
                                    width="27.0588235"
                                    height="20"
                                    rx="3"
                                />
                                <circle
                                    id="Oval"
                                    fill="#E61C24"
                                    cx="10.5882353"
                                    cy="10"
                                    r="4.70588235"
                                />
                                <circle
                                    id="Oval"
                                    fill="#F99F1B"
                                    cx="16.4317647"
                                    cy="10"
                                    r="4.70588235"
                                />
                                <path
                                    d="M13.51,6.31529412 C12.383167,7.20611512 11.7258957,8.56357572 11.7258957,10 C11.7258957,11.4364243 12.383167,12.7938849 13.51,13.6847059 C14.636833,12.7938849 15.2941043,11.4364243 15.2941043,10 C15.2941043,8.56357572 14.636833,7.20611512 13.51,6.31529412 Z"
                                    id="Path"
                                    fill="#F26622"
                                />
                            </g>
                            <g id="amex" transform="translate(137.000000, 16.500000)">
                                <path
                                    d="M25.2941176,0 L1.76470588,0 C0.79,0 0,0.79 0,1.76470588 L0,18.2352941 C0,19.21 0.79,20 1.76470588,20 L25.2941176,20 C26.2688235,20 27.0588235,19.21 27.0588235,18.2352941 L27.0588235,1.76470588 C27.0588235,0.79 26.2688235,0 25.2941176,0 Z"
                                    id="Path"
                                    fill="#007AC6"
                                />
                                <path
                                    d="M5.60764706,10.2523529 L6.97823529,10.2523529 L6.29294118,8.57176471 L5.60764706,10.2523529 Z M24.7058824,7.05882353 L21.1947059,7.05882353 L20.3382353,7.98294118 L19.5670588,7.05882353 L12.1164706,7.05882353 L11.4311765,8.57117647 L10.7458824,7.05882353 L7.74823529,7.05882353 L7.74823529,7.73117647 L7.40588235,7.05882353 L4.83647059,7.05882353 L2.35294118,12.9411765 L5.35058824,12.9411765 L5.69294118,12.0170588 L6.54941176,12.0170588 L6.89176471,12.9411765 L10.2317647,12.9411765 L10.2317647,12.2688235 L10.4888235,12.9411765 L12.2017647,12.9411765 L12.4588235,12.1847059 L12.4588235,12.9411765 L19.3105882,12.9411765 L20.1670588,12.0170588 L20.9376471,12.9411765 L24.4488235,12.9411765 L22.2223529,10 L24.7058824,7.05882353 Z M14.3429412,12.1005882 L13.4005882,12.1005882 L13.4005882,8.82352941 L11.9447059,12.1005882 L11.0882353,12.1005882 L9.63235294,8.82352941 L9.63235294,12.1005882 L7.66235294,12.1005882 L7.32,11.1764706 L5.26470588,11.1764706 L4.92235294,12.1005882 L3.80882353,12.1005882 L5.60705882,7.89882353 L7.06294118,7.89882353 L8.69,11.8482353 L8.69,7.89882353 L10.3170588,7.89882353 L11.6017647,10.7558824 L12.8005882,7.89882353 L14.4276471,7.89882353 L14.4276471,12.1005882 L14.3429412,12.1005882 Z M22.8217647,12.1005882 L21.5370588,12.1005882 L20.4235294,10.6717647 L19.1388235,12.1005882 L15.2847059,12.1005882 L15.2847059,7.89882353 L19.2241176,7.89882353 L20.4229412,9.24352941 L21.7076471,7.89882353 L22.9064706,7.89882353 L21.0229412,10 L22.8217647,12.1005882 Z M16.2270588,8.73941176 L16.2270588,9.49588235 L18.3682353,9.49588235 L18.3682353,10.3364706 L16.2270588,10.3364706 L16.2270588,11.1764706 L18.6252941,11.1764706 L19.7388235,9.91588235 L18.7111765,8.73941176 L16.2270588,8.73941176 Z"
                                    id="Shape"
                                    fill="#FFFFFF"
                                    fillRule="nonzero"
                                />
                            </g>
                        </g>
                        <g id="cvv-master" transform="translate(16.000000, 52.500000)">
                            <g id="Group" transform="translate(0.000000, 0.057266)">
                                <rect
                                    id="Rectangle"
                                    fill="#9996CD"
                                    x="0"
                                    y="0"
                                    width="66"
                                    height="44.8148148"
                                    rx="5"
                                />
                                <rect
                                    id="Rectangle"
                                    fill="#4B5D6B"
                                    x="0"
                                    y="11"
                                    width="66"
                                    height="10"
                                />
                                <rect
                                    id="Rectangle-Copy"
                                    fill="#FFFFFF"
                                    x="6"
                                    y="29"
                                    width="54"
                                    height="10"
                                    rx="2"
                                />
                                <path
                                    d="M8,29 L40.5,29 L40.5,39 L8,39 C6.8954305,39 6,38.1045695 6,37 L6,31 C6,29.8954305 6.8954305,29 8,29 Z"
                                    id="Rectangle-Copy"
                                    fill="url(#pattern-3)"
                                />
                                <text
                                    id="123"
                                    fontFamily="Barlow-Bold, Barlow"
                                    fontSize="5"
                                    fontWeight="bold"
                                    fill="#000000"
                                >
                                    <tspan x="45.9026113" y="36">
                                        123
                                    </tspan>
                                </text>
                            </g>
                            <mask id="mask-6" fill="white">
                                <use xlinkHref="#path-5" />
                            </mask>
                            <g id="Oval">
                                <use
                                    fill="black"
                                    fillOpacity="1"
                                    filter="url(#filter-7)"
                                    xlinkHref="#path-5"
                                />
                                <use
                                    stroke="#FFFFFF"
                                    strokeWidth="1"
                                    fill="#D8D8D8"
                                    fillRule="evenodd"
                                    xlinkHref="#path-5"
                                />
                            </g>
                            <g id="Group" mask="url(#mask-6)">
                                <g transform="translate(-21.317403, -16.000000)">
                                    <rect
                                        id="Rectangle"
                                        fill="#9996CD"
                                        fillRule="evenodd"
                                        x="0.53088363"
                                        y="0"
                                        width="104.647973"
                                        height="71.0572656"
                                        rx="5"
                                    />
                                    <rect
                                        id="Rectangle"
                                        fill="#4B5D6B"
                                        fillRule="evenodd"
                                        x="4"
                                        y="22.2840307"
                                        width="97.7097402"
                                        height="14.8045061"
                                    />
                                    <rect
                                        id="Rectangle-Copy"
                                        fill="#FFFFFF"
                                        fillRule="evenodd"
                                        x="12.8827037"
                                        y="42.9330677"
                                        width="79.9443329"
                                        height="14.8045061"
                                        rx="2"
                                    />
                                    <path
                                        d="M14.8827037,42.9330677 L63.9582497,42.9330677 L63.9582497,57.7375737 L14.8827037,57.7375737 C13.7781342,57.7375737 12.8827037,56.8421432 12.8827037,55.7375737 L12.8827037,44.9330677 C12.8827037,43.8284982 13.7781342,42.9330677 14.8827037,42.9330677 Z"
                                        id="Rectangle-Copy"
                                        fill="url(#pattern-8)"
                                        fillRule="evenodd"
                                    />
                                    <text
                                        id="123"
                                        fill="none"
                                        fontFamily="Barlow-Bold, Barlow"
                                        fontSize="8"
                                        fontWeight="bold"
                                    >
                                        <tspan x="72.2512529" y="53.3353207" fill="#000000">
                                            123
                                        </tspan>
                                    </text>
                                </g>
                            </g>
                        </g>
                        <g id="cvv-master-copy" transform="translate(137.000000, 45.500000)">
                            <g id="Group" transform="translate(0.000000, 7.000000)">
                                <rect
                                    id="Rectangle"
                                    fill="#007AC6"
                                    x="0"
                                    y="0"
                                    width="66"
                                    height="44.8148148"
                                    rx="5"
                                />
                                <ellipse
                                    id="Oval"
                                    fillOpacity="0.4"
                                    fill="#FFFFFF"
                                    cx="33"
                                    cy="21.4875"
                                    rx="11.75"
                                    ry="13.5125"
                                />
                                <rect
                                    id="Rectangle-Copy"
                                    fill="#FFFFFF"
                                    opacity="0.6"
                                    x="6"
                                    y="25"
                                    width="11.5"
                                    height="6"
                                />
                                <rect
                                    id="Rectangle-Copy-2"
                                    fill="#FFFFFF"
                                    opacity="0.6"
                                    x="19.5"
                                    y="25"
                                    width="11.5"
                                    height="6"
                                />
                                <rect
                                    id="Rectangle-Copy-4"
                                    fill="#FFFFFF"
                                    opacity="0.6"
                                    x="33"
                                    y="25"
                                    width="27"
                                    height="6"
                                />
                            </g>
                            <mask id="mask-11" fill="white">
                                <use xlinkHref="#path-10" />
                            </mask>
                            <g id="Oval">
                                <use
                                    fill="black"
                                    fillOpacity="1"
                                    filter="url(#filter-12)"
                                    xlinkHref="#path-10"
                                />
                                <use
                                    stroke="#FFFFFF"
                                    strokeWidth="1"
                                    fill="#D8D8D8"
                                    fillRule="evenodd"
                                    xlinkHref="#path-10"
                                />
                            </g>
                            <g id="Group" mask="url(#mask-11)">
                                <g transform="translate(-21.317403, -27.000000)">
                                    <rect
                                        id="Rectangle"
                                        fill="#007AC6"
                                        fillRule="evenodd"
                                        x="0.53088363"
                                        y="0"
                                        width="104.647973"
                                        height="71.0572656"
                                        rx="5"
                                    />
                                    <rect
                                        id="Rectangle-Copy"
                                        fill="#FFFFFF"
                                        fillRule="evenodd"
                                        opacity="0.6"
                                        x="12.8827037"
                                        y="55"
                                        width="73.6163226"
                                        height="11.1398268"
                                    />
                                    <path
                                        d="M14.8827037,42.9330677 L63.9582497,42.9330677 L63.9582497,57.7375737 L14.8827037,57.7375737 C13.7781342,57.7375737 12.8827037,56.8421432 12.8827037,55.7375737 L12.8827037,44.9330677 C12.8827037,43.8284982 13.7781342,42.9330677 14.8827037,42.9330677 Z"
                                        id="Rectangle-Copy"
                                        fill="url(#pattern-13)"
                                        fillRule="evenodd"
                                    />
                                    <text
                                        id="1234"
                                        fill="none"
                                        fontFamily="Barlow-Bold, Barlow"
                                        fontSize="8"
                                        fontWeight="bold"
                                    >
                                        <tspan x="75.0575632" y="48.3353207" fill="#FFFFFF">
                                            1234
                                        </tspan>
                                    </text>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
