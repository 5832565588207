import * as React from "react";
import styled from "@emotion/styled";

import { linkButtonStyle } from "./elements";

export interface Props {
    buttonText: string;
    showButton: boolean;
    onClick: () => void;
}

export const PlanSelect: React.FunctionComponent<Props> = ({ showButton, buttonText, onClick, children, ...props }) => {
    return (
        <Container showButton={showButton}>
            {showButton && <Button type="button" onClick={onClick}>{buttonText}</Button>}
            <Content>{children}</Content>
        </Container>
    );
};

const Container = styled.div<{showButton: boolean}>`
    width: 100%;

    display: grid;
    grid-template-areas:
        ${props => props.showButton ? `"content button"` : `"content"`};
    grid-template-columns: ${props => props.showButton ? "1fr auto" : "1fr"};

    align-items: center;
`;

const Content = styled.div`
    grid-area: content;
`;

const Button = styled.button`
    grid-area: button;
    color: #00ffb3;
    ${linkButtonStyle};
    align-self: flex-start;
    margin-left: 8px;
`;
